(function (window, Swiper) {
    "use strict";

    var productSwiper = $(".plp-product-swiper");

    if (productSwiper?.length) {
        if (window.isTouchDevice()) {
            productSwiper.find(".swiper-button-next, .swiper-button-prev").addClass("hide");

            return;
        }

        productSwiper.each(function () {
            initializeSwiper(this);
        });

        function initializeSwiper(swiper) {
            var swiperSelectorName = "#" + swiper.id;
            var numberOfImages = $(swiper).find(".ProductImageList").length;
            var swiperInstance = document.getElementById(swiper.id).swiper;

            if (numberOfImages > 1 && !swiperInstance) {
                var swiperJs = new Swiper(swiperSelectorName, {
                    navigation: {
                        nextEl: ".swiper-button-next",
                        prevEl: ".swiper-button-prev",
                    },
                    slidesPerView: 1,
                    effect: "fade",
                    loop: true,
                });

                $(swiper).hover(
                    function () {
                        swiperJs.slideNext();
                    },
                    function () {
                        swiperJs.slideToLoop(0, 0, false);
                    },
                );

                $("#activecolumns").change(function () {
                    swiperJs.update();
                });
            }
        }
    }
})(window, window.Swiper);
