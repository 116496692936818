(function ($, undefined) {
    "use strict";

    $(function () {
        $("div.CompareButtonContainer > input.CompareButton")
            .click(function () {
                // Gather up product id's
                var pid = GatherProductId();

                var title = $(this).attr("data-title"),
                    url = $(this).attr("data-url") + pid.join(","),
                    opts = { iframe: true, innerWidth: 1000, innerHeight: 500, href: url, title: title, open: true };

                var bodyContent =
                    '<div id="workarea"> </div>' +
                    '<div class="row addressbook-options-buttons"> <a href="#" class="btnYes "></a>  <a href="#" class="btnNo "></a>  </div>' +
                    "</div></div></div></div>";

                modalHelper.setupModal({
                    modalName: "CompareModal",
                    contentHtml: bodyContent,
                    iFrameUrl: url,
                    iFrameOptions: opts,
                    titleHtml: title,
                });

                return false;
            })
            .prop("disabled", true);

        $("div[class*=comparechk] input[type=checkbox]").click(function () {
            var buttons = $("div.CompareButtonContainer > input.CompareButton"),
                labels = $("div.CompareButtonContainer > span.CompareMessage"),
                count = GatherProductId().length,
                max = buttons.data("maxproducts");

            if (count < 2) {
                buttons.prop("disabled", true).prop("title", labels.data("toofew"));
                labels.text(labels.data("toofew"));
            } else if (count > max) {
                buttons.prop("disabled", true).prop("title", labels.data("toomany"));
                labels.text(labels.data("toomany"));
            } else {
                buttons.prop("disabled", false).prop("title", labels.data("docompare"));
                labels.text(labels.data("docompare"));
            }
        });
    });

    function GatherProductId() {
        var selector = "div[class*=comparechk] input[type=checkbox]:checked",
            idCollection = [];

        $(selector).each(function () {
            idCollection.push($(this).data("productid"));
        });
        return idCollection;
    }
})(window.jQuery);
